<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Pelatihan </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" class="pr-4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :key="fotoKey"
                    :src="currentData.FotoUrl"
                    contain
                  ></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                v-model="currentData.file_foto"
                accept="image/*"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
                @change="loadImage"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="currentData.kegiatan_title"
                    outlined
                    name="input-7-4"
                    label="Judul"
                    dense
                    value=""
                    rows="3"
                    :rules="currentData.kegiatanTitleRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="currentData.kegiatan_desc"
                    outlined
                    name="input-7-4"
                    label="Deskripsi"
                    dense
                    value=""
                    :rules="currentData.kegiatanDescRules"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="currentData.informasi"
                    outlined
                    name="input-7-4"
                    label="Informasi"
                    dense
                    value=""
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="currentData.message_broadcast"
                    outlined
                    name="input-7-4"
                    label="Message Broadcast"
                    dense
                    value=""
                    :readonly="isEditable == 0"
                    rows="10"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h5>Konten</h5>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-textarea
                v-model="currentData.prasyarat_khusus"
                outlined
                name="input-7-4"
                label="Prasyarat Khusus"
                dense
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentData.harga"
                    label="Harga"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentData.nomor_skp"
                    label="No SKP"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentData.tanggal_awal"
                    label="Tanggal Awal"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="currentData.tanggal_akhir"
                    label="Tanggal Akhir"
                    type="date"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="currentData.skp_narasumber"
                label="SKP Narasumber"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="currentData.skp_moderator"
                label="SKP Moderator"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="currentData.skp_panitia"
                label="SKP Panitia"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="currentData.skp_peserta"
                label="SKP Peserta"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="currentData.kuota"
                label="Kuota"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="currentData.penyelenggara"
                label="Penyelenggara"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                v-model="currentData.selectedKategori"
                label="Kategori"
                clearable
                dense
                outlined
                :items="items_kategori"
                item-text="value"
                :rules="currentData.kategoriRules"
              ></v-combobox>
            </v-col>

            <!-- <v-col cols="12" sm="6" md="6">
              <v-combobox
                v-model="currentData.selectedDpd"
                label="DPD"
                clearable
                dense
                outlined
                :items="items_dpd"
                item-text="nama"
                :rules="currentData.dpdRules"
                :disabled="isDpdPengurus"
              ></v-combobox>
            </v-col> -->
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn style="margin-right: 10px" @click="doBatal">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import KegiatanService from "../services/KegiatanService";
import MasterService from "../services/MasterService";
// import KomisariatService from "../services/KomisariatService";
import DPDService from "../services/DPDService";
import store from "../store/index";

export default {
  name: "KegiatanAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pelatihan",
        disabled: false,
        exact: true,
        to: { name: "Pelatihan" },
      },
      { text: "Tambah Pelatihan", disabled: true },
    ],
    valid: true,
    currentData: {
      FotoUrl: "",
      file_foto: null,
      kegiatan_id: "",
      kegiatan_title: "",
      kegiatan_desc: "",
      informasi: "",
      prasyarat_khusus: "",
      harga: "",
      tanggal_awal: "",
      tanggal_akhir: "",
      jenis_kegiatan: "",
      skp_narasumber: "",
      skp_moderator: "",
      skp_panitia: "",
      skp_peserta: "",
      nomor_skp: "",
      penyelenggara: "",
      kategori: "",
      selectedKategori: "",
      selectedKomisariat: "",
      selectedDpd: "",
      kuota: "",
      message_broadcast: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
      // komisariatRules: [(v) => !!v || "Komisariat harus diisi"],
      dpdRules: [(v) => !!v || "DPD harus diisi"],
      kategoriRules: [(v) => !!v || "Kategori harus diisi"],
    },
    items_kategori: [],
    // items_komisariat: [],
    items_dpd: [],
    fotoKey: 0,
  }),
  computed: {
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    // isDpdPengurus() {
    //   if (store.getters.getUser.dpd_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  mounted: async function () {
    // this.getListKomisariat();
    this.getListDpd();
    this.getListKategoriKegiatan();
  },
  methods: {
    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.currentData.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getListDpd() {
      DPDService.dpdListAllOnly()
        .then((res) => {
          this.items_dpd = res.data.list_data;

          console.log(this.items_dpd);
          console.log(this.getDpdPengurusId);

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.currentData.selectedDpd = entry;
              }
            });
          } else {
            this.getDataFromApi();
          }

          // if (this.getKomisariatId) {
          //   this.items_komisariat.forEach((entry) => {
          //     if (entry.id == this.getKomisariatId) {
          //       this.currentData.selectedKomisariat = entry;
          //       this.getDataFromApi();
          //     }
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    async getListKategoriKegiatan() {
      MasterService.kategoriKegiatanList()
        .then((res) => {
          this.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.create(this.currentData, this.currentData.file_foto)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Pelatihan berhasil");

          this.$router
            .push("/kegiatan-edit/" + res.data.kegiatan.kegiatan_id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Pelatihan gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped></style>
