<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Data Rumah Sakit </v-card-title>
        <v-fab-transition>
          <v-btn
            v-if="isEditable == 0"
            class="mt-8 mr-4"
            color="pink"
            dark
            absolute
            top
            right
            fab
            @click="changeEditable"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="pl-4 pr-4 pt-4"
        >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.emergency_call"
                    label="Emergency Call"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.contact_us"
                    label="Contact"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.alamat"
                    label="Alamat"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.latitude"
                    label="Latitude"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.longitude"
                    label="Longitude"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.moto"
                    label="Moto"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_facebook"
                    label="Link Facebook"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_youtube"
                    label="Link Youtube"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_instagram"
                    label="Link Instagram"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_whatsapp"
                    label="Link Whatsapp"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_linkedin"
                    label="Link LinkedIn"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="dataRs.link_google_play"
                    label="Link Google Play"
                    outlined
                    dense
                    :readonly="isEditable == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                v-if="isEditable == 1"
                style="margin-right: 10px"
                @click="doBatal"
                >Batal</v-btn
              >
              <v-btn
                v-if="isEditable == 1"
                dark
                color="primary"
                type="button"
                @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import SettingRSService from "../../services/SettingRSService";

export default {
  name: "SettingRS",
  components: {},
  data: () => ({
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Web Content",
        disabled: false,
        exact: true,
        to: { name: "/webcontent/setting-rs" },
      },
      { text: "Setting Rumah sakit", disabled: true },
    ],
    valid: true,
    responseData: null,
    dataRs: {
      emergency_call: "",
      contact_us: "",
      latitude: null,
      longitude: null,
      alamat: "",
      moto: "",
      link_facebook: "",
      link_youtube: "",
      link_instagram: "",
      link_whatsapp: "",
      link_linkedin: "",
      link_google_play: "",
    },
  }),
  mounted: async function () {
    this.getDataRumahSakit();
  },
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    async getDataRumahSakit() {
      SettingRSService.dataRumahSakit()
        .then((res) => {
          this.responseData = res.data.data;
          this.dataRs = res.data.data;
          console.log(this.responseData);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.isEditable = 0;
      this.getDataRumahSakit();
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      SettingRSService.update(1, this.dataRs)
        .then(() => {
          this.isEditable = 0;
          this.$toast.success("Update Data RS berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.getDataRumahSakit();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Update data gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>

<style scoped></style>
