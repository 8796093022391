<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <div class="d-flex mx-7 pt-5 justify-space-between">
          <h5 class="text-h5">Data Berita</h5>
          <v-btn v-if="!isEdit" density="compact" @click="isEdit = true"
            >Edit <v-icon class="ml-1">mdi-pen</v-icon></v-btn
          >
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="currentData.judul"
                  :disabled="!isEdit"
                  label="Judul"
                  clearable
                  dense
                  outlined
                  required
                ></v-text-field>
                <v-combobox
                  v-model="currentData.selectedKategori"
                  :disabled="!isEdit"
                  label="Kategori"
                  dense
                  outlined
                  :items="currentData.items_kategori"
                  item-text="nama"
                ></v-combobox>
                <v-combobox
                  v-model="currentData.selectedHide"
                  :disabled="!isEdit"
                  label="Publish"
                  dense
                  outlined
                  :items="currentData.items_hide"
                  item-text="name"
                  item-value="id"
                ></v-combobox>
                <v-file-input
                  v-model="filefoto"
                  :disabled="!isEdit"
                  label="Upload Foto Thumbnail (Cover)"
                  accept="image/*"
                  prepend-icon="mdi-image"
                  clearable
                  dense
                  outlined
                  @change="loadImage"
                >
                </v-file-input>
                <v-row v-if="currentData.FotoUrl" class="my-5">
                  <v-col cols="12" sm="6" md="4">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img :src="currentData.FotoUrl" contain></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="currentData.konten"
                  :disabled="!isEdit"
                  label="Konten"
                  clearable
                  dense
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- Start Gallery  -->
            <div v-if="isEdit">
              <CardGallery />
            </div>
            <!-- End Gallery  -->
          </v-container>
        </v-card-text>
        <div style="height: 60px"></div>
        <v-card-actions v-if="isEdit">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="isEdit = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KategoriBeritaService from "../../services/KategoriBeritaService";
import CommonService from "../../services/CommonService";
import BeritaService from "../../services/web_content/BeritaService";
import CardGallery from "../../components/CardGallery.vue";

export default {
  name: "App",
  components: {
    CardGallery,
  },
  setup() {
    return {};
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Berita",
        disabled: false,
        href: "/webcontent/berita",
      },
      {
        text: "Edit Berita",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    isEdit: false,
    filefoto: null,
    currentData: {
      id: "",
      judul: "",
      selectedKategori: "",
      selectedHide: "",
      konten: null,
      foto_url: "",
      FotoUrl: "",
      items_hide: [
        { id: 1, name: "Publish" },
        { id: 2, name: "Hide" },
      ],
      items_kategori: [],
    },
  }),
  mounted() {
    this.getListKategori();
  },
  methods: {
    loadImage: function (data) {
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },
    async getListKategori() {
      KategoriBeritaService.kategoriBeritaListAll()
        .then((res) => {
          this.currentData.items_kategori = res.data.list_data;
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      const data = sessionStorage.getItem("item_berita");
      const berita = JSON.parse(data);
      this.currentData.id = berita.id;
      this.currentData.judul = berita.judul;
      this.currentData.konten = berita.konten;
      this.state._content = berita.konten;
      this.state.content = berita.konten;
      this.currentData.FotoUrl = berita.foto_url;

      this.currentData.items_hide.forEach((entry) => {
        if (entry.id == berita.data_status) {
          this.currentData.selectedHide = entry;
        }
      });

      if (berita.kategori_berita_id) {
        this.currentData.items_kategori.forEach((entry) => {
          if (entry.id == berita.kategori_berita_id) {
            this.currentData.selectedKategori = entry;
          }
        });
      } else {
        this.currentData.selectedKategori = null;
      }
    },
    doSave: async function () {
      if (this.currentData.judul == "" || this.currentData.konten == null) {
        CommonService.showWarningToast("Lengkapi data terlebih dahulu!");
      } else {
        BeritaService.update(
          this.currentData,
          this.currentData.konten,
          this.filefoto,
        )
          .then(() => {
            CommonService.showSuccessToast("update berita berhasil");
            this.$router.push("/webcontent/berita");
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("update berita gagal. Coba lagi");
          });
      }
    },
  },
};
</script>
