import http from "../http-common";
/* eslint-disable */
class PoliService {
  poliList(page, itemPerPage, keyword) {
    return http.get(
      "/poli-dokter/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data);
    return http.post("/poli-dokter", bodyFormData, {
      headers: {
        "Content-type": "application/json",
        Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.name);

    return http.put(`/poli-dokter/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/poli-dokter/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  // sub poli 
  subPoliList(page, itemPerPage, keyword, parent_id) {
    return http.get(
      "/sub-poli-dokter/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&parent_id=" +
        parent_id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  createSubPoli(name, parent_id) {
    var bodyFormData = new FormData();
    bodyFormData.append("parent_id", parent_id);
    bodyFormData.append("nama", name);
    return http.post("/sub-poli-dokter", bodyFormData, {
      headers: {
        "Content-type": "application/json",
        Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  updateSubPoli(id, name) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", name);

    return http.put(`/sub-poli-dokter/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  deleteSubPoli(id) {
    return http.delete(`/sub-poli-dokter/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
  // end sub poli 
}

export default new PoliService();
