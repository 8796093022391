<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Layanan Superior
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari berdasarkan nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- Start dialog tambah  -->
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Data Layanan</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.name"
                        label="Nama"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="currentData.description"
                        label="Deskripsi"
                        required
                      ></v-text-field>
                      <v-sheet v-if="showImage" color="white" elevation="2">
                        <div style="width: 100%">
                          <v-img
                            :key="fotoKey"
                            :src="currentData.FotoUrl"
                            contain
                            width="100%"
                          ></v-img>
                        </div>
                      </v-sheet>
                      <v-file-input
                        v-model="currentData.file_foto"
                        accept="image/*"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        @change="loadImage"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closePopUp">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- end dialog tambah  -->

          <!-- Start dialog delete  -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- end dialog delete  -->
        </v-card-title>
        <!-- Start datatable  -->
        <div class="pb-6 px-4">
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium class="mr-2" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <!-- end datatable  -->
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import LayananSuperiorService from "../../services/LayananSuperiorService";
import CommonService from "../../services/CommonService";
export default {
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Master",
        disabled: false,
        href: "master-layanan-superior",
      },
      {
        text: "Layanan Superior",
        disabled: true,
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Deskripsi", value: "deskripsi", sortable: false },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        sortable: false,
        class: "actions-header",
      },
    ],
    showImage: false,
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    fotoKey: null,
    currentData: {
      id: null,
      name: "",
      description: "",
      FotoUrl: null,
      file_foto: null,
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      LayananSuperiorService.layananList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    loadImage: function (data) {
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.file_foto = null;
      }
    },

    doAdd: async function () {
      this.showImage = true;
      this.dialog = true;
      this.currentData.name = "";
      this.currentData.description = "";
      this.currentData.id = null;
      this.fotoKey = 0;
      this.currentData.FotoUrl = "";
      this.currentData.file_foto = null;
    },

    doSave: async function () {
      if (this.currentData.name != "" && this.currentData.name != null) {
        if (this.currentData.id == null) {
          LayananSuperiorService.create(
            this.currentData,
            this.currentData.file_foto,
          )
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast("Tambah data layanan berhasil");
              this.currentData.name = null;
              this.currentData.description = null;
              this.currentData.id = null;
              this.fotoKey = 0;
              this.currentData.FotoUrl = "";
              this.currentData.file_foto = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah data layanan gagal. Coba lagi",
              );
            });
        } else {
          LayananSuperiorService.update(
            this.currentData.id,
            this.currentData,
            this.currentData.file_foto,
          )
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast("Update data layanan berhasil");
              this.currentData.name = null;
              this.currentData.description = "";
              this.currentData.id = null;
              this.fotoKey = 0;
              this.currentData.FotoUrl = "";
              this.currentData.file_foto = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Update data layanan gagal. Coba lagi",
              );
            });
        }
      } else {
        alert("Nama harus diisi.");
      }
    },

    editItem(item) {
      this.currentData.name = item.nama;
      this.currentData.description = item.deskripsi;
      if (item.foto_original != null) {
        this.currentData.FotoUrl = item.foto;
        this.showImage = true;
      } else {
        this.showImage = false;
      }
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.id = this.editedItem.id;
    },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      LayananSuperiorService.delete(this.editedItem.id)
        .then(() => {
          CommonService.showSuccessToast("Hapus data layanan berhasil");
          this.currentData.name = null;
          this.currentData.description = "";
          this.currentData.id = null;
          this.fotoKey = 0;
          this.currentData.FotoUrl = "";
          this.currentData.file_foto = null;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus data layanan gagal. Coba lagi");
        });
    },

    closePopUp() {
      this.showImage = false;
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.actions-header {
  margin-right: 20px !important;
}
</style>
