import http from "../../http-common";
/* eslint-disable */
class TestimoniService {
  testimoniList(page, itemPerPage, keyword) {
    return http.get(
      "/testimoni/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data, konten, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("testimoni", konten);
    bodyFormData.append("title", data.judul);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.code);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/testimoni", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data, konten, photo) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.id);
    bodyFormData.append("testimoni", konten);
    bodyFormData.append("title", data.judul);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("data_status", data.selectedHide.code);
    }

    if (photo != null) bodyFormData.append("photo", photo);

    return http.put(`/testimoni/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/testimoni/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new TestimoniService();
