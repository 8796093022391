<template>
  <div>
    <!-- Start Gallery  -->
    <v-btn density="compact" class="mb-5" @click="openGallery"
      >Gallery <v-icon class="ml-1">mdi-gallery</v-icon></v-btn
    >
    <v-dialog v-model="showGallery" max-width="800px">
      <v-card>
        <v-card-title>
          Gallery
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="px-4">
          <v-col cols="12" sm="6">
            <v-file-input
              v-model="filefotokonten"
              :disabled="disable"
              label="Upload Foto"
              accept="image/*"
              prepend-icon="mdi-image"
              clearable
              dense
              outlined
              @change="UploadImage"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" class="mt-0">
            <div v-if="galleryPhotos" class="mb-5">
              <v-row v-if="galleryPhotos.length > 0">
                <v-col v-if="disable" cols="6" sm="4" class="text-center">
                  <p>Uploading Photo..</p>
                </v-col>
                <v-col
                  v-for="item in galleryPhotos"
                  :key="item.id"
                  cols="6"
                  sm="4"
                >
                  <v-img
                    :src="item.photo"
                    style="width: 100%; height: 120px; object-fit: cover"
                  ></v-img>
                  <div class="d-flex mt-1 justify-between">
                    <v-col class="" @click="copyUrlImage(item.photo)"
                      >Copy URL</v-col
                    >
                    <v-col
                      class="text-right"
                      style="color: red"
                      @click="deleteImage(item.id)"
                      >Delete</v-col
                    >
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="text-center">
              <v-pagination v-model="page" :length="length"></v-pagination>
            </div>
          </v-col>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showGallery = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Gallery  -->
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import http from "../http-common";
import CommonService from "../services/CommonService";

const showGallery = ref(false);
const filefotokonten = ref(null);
const page = ref(1);
const itemPerPage = ref(6);
const length = ref(1);
const galleryPhotos = ref([]);
const disable = ref(false);

const copyUrlImage = (url) => {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      CommonService.showInfoToast("URL copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
};

const deleteImage = async (data) => {
  try {
    await http.delete("/gambar/" + data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
    getImages();
  } catch (e) {
    console.error(e);
  }
};

const getImages = async () => {
  try {
    const images = await http.get(
      "/gambar/list?page=" + page.value + "&item_per_page=" + itemPerPage.value,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
    galleryPhotos.value = images.data.list_data;
    length.value = Math.ceil(images.data.total / itemPerPage.value);
    filefotokonten.value = null;
    console.log(galleryPhotos.value);
  } catch (e) {
    console.error(e);
  }
};

const UploadImage = async () => {
  disable.value = true;
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("photo", filefotokonten.value);
    await http.post("/gambar", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
    getImages();
  } catch (e) {
    console.error(e);
  }
  disable.value = false;
};

const openGallery = () => {
  showGallery.value = true;
  getImages();
};

onMounted(() => {});
</script>
