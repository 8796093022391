import http from "../http-common";
/* eslint-disable */
class LayananSuperiorService {
  layananList(page, itemPerPage, keyword) {
    return http.get(
      "/layanan-superior/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      },
    );
  }

  create(data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.name);
    bodyFormData.append("deskripsi", data.description);
    if (foto) {
      bodyFormData.append("foto", foto);
    }
    return http.post("/layanan-superior", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.name);
    bodyFormData.append("deskripsi", data.description);
    if (foto) {
      bodyFormData.append("foto", foto);
    }

    return http.put(`/layanan-superior/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/layanan-superior/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new LayananSuperiorService();
