<template>
  <div>
    <v-card>
      <div class="d-flex justify-space-between">
        <v-card-title> Jadwal Dokter </v-card-title>

        <v-btn color="primary" dark class="mt-3 mr-3" @click="tambahJadwal">
          Tambah
        </v-btn>
      </div>
      <!-- Start datatable  -->
      <div class="pb-6 px-4">
        <v-col cols="4">
          <v-combobox
            v-model="selectedPoli"
            label="Poli"
            clearable
            dense
            outlined
            :items="items_poli"
            item-text="nama"
            item-value="id"
            @change="changePoli"
          ></v-combobox>
        </v-col>
        <v-data-table
          :headers="headers"
          :items="jadwal_dokter"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.jam_dokter`]="{ item }">
            <ul>
              <li v-for="jam in item.jam_dokter" :key="jam.no">
                {{ jam.jam_awal }} - {{ jam.jam_akhir }}
              </li>
            </ul>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editJadwal(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium class="mr-3" @click="removeJadwal(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <!-- end datatable  -->
      <!-- Start dialog tambah  -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Jadwal per hari</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="px-2">
              <v-container>
                <!-- Button to add a new time -->
                <div class="text-end justify-end">
                  <v-btn @click="addTime">Tambah Sesi</v-btn>
                </div>
                <v-combobox
                  v-model="selectedAddPoli"
                  label="Poli"
                  required
                  :rules="addPoliRules"
                  clearable
                  dense
                  outlined
                  :items="items_poli"
                  item-text="nama"
                  item-value="id"
                  class="mt-5"
                ></v-combobox>
                <v-combobox
                  v-model="selectedDay"
                  label="Hari"
                  required
                  :rules="hariRules"
                  clearable
                  dense
                  outlined
                  :items="days"
                  item-text="name"
                  item-value="value"
                ></v-combobox>

                <!-- Dynamic comboboxes -->
                <div
                  v-for="(item, index) in times"
                  :key="index"
                  class="combobox-field"
                >
                  <div class="d-flex justify-start mt-3 pb-0 mb-0">
                    <v-col cols="5" class="p-0">
                      <v-text-field
                        v-model="item.jam_awal"
                        label="Jam Awal"
                        type="time"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5" class="p-0">
                      <v-text-field
                        v-model="item.jam_akhir"
                        label="Jam Akhir"
                        type="time"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="text-center justify-center">
                      <v-btn icon @click="removeTime(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </div>
                </div>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog tambah  -->
      <!-- Start dialog edit  -->
      <v-dialog v-model="dialogEdit" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit jadwal per hari</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation class="px-2">
              <v-container>
                <!-- Button to add a new time -->
                <div class="text-end justify-end">
                  <v-btn @click="addTime">Tambah Sesi</v-btn>
                </div>
                <v-combobox
                  v-model="selectedPoli.nama"
                  label="Poli"
                  class="mt-5"
                  :items="days"
                  item-text="name"
                  item-value="id"
                  readonly
                  disabled
                ></v-combobox>
                <v-combobox
                  v-model="selectedDay"
                  label="Hari"
                  required
                  :rules="hariRules"
                  :items="days"
                  item-text="name"
                  item-value="value"
                ></v-combobox>

                <!-- Dynamic comboboxes -->
                <div
                  v-for="(item, index) in times"
                  :key="index"
                  class="combobox-field"
                >
                  <div class="d-flex justify-start mt-3 pb-0 mb-0">
                    <v-col cols="5" class="p-0">
                      <v-text-field
                        v-model="item.jam_awal"
                        label="Jam Awal"
                        type="time"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5" class="p-0">
                      <v-text-field
                        v-model="item.jam_akhir"
                        label="Jam Akhir"
                        type="time"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="text-center justify-center">
                      <v-btn icon @click="removeTime(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </div>
                </div>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEdit"> Close </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog edit -->
      <!-- Start dialog delete  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan menghapus data ini?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="fetchDeleteJadwal"
              >Ya</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog delete  -->
    </v-card>
  </div>
</template>

<script>
import NakesService from "../../services/NakesService";
import CommonService from "../../services/CommonService";

export default {
  props: {
    id: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    loading: true,
    days: [
      { value: "1", name: "Senin" },
      { value: "2", name: "Selasa" },
      { value: "3", name: "Rabu" },
      { value: "4", name: "Kamis" },
      { value: "5", name: "Jumat" },
      { value: "6", name: "Sabtu" },
      { value: "7", name: "Minggu" },
    ],
    headers: [
      { text: "Hari", value: "nama_hari", sortable: false },
      { text: "Jam", value: "jam_dokter", align: "center", sortable: false },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        sortable: false,
        class: "actions-header",
      },
    ],
    selectedDay: "",
    hari: "",
    times: [{ jam_awal: null, jam_akhir: null }],
    jadwal_dokter: [],
    newJadwal: [],
    formData: {},
    poliName: "",
    items_poli: [],
    selectedPoli: "",
    selectedAddPoli: "",
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    valid: true,
    hariRules: [(v) => !!v || "Hari harus diisi"],
    addPoliRules: [(v) => !!v || "Poli harus diisi"],
  }),
  // watch: {
  //   selected: {},
  // },
  mounted() {
    this.getPoliDokterAll();
  },
  methods: {
    addTime() {
      this.times.push({ hari: null, jam_awal: null, jam_akhir: null });
    },
    removeTime(index) {
      this.times.splice(index, 1);
    },
    async getPoliDokterAll() {
      NakesService.poliDokterAll(this.id)
        .then((res) => {
          if (res.data.list_data.length > 0) {
            this.items_poli = res.data.list_data;
            if (res.data.list_data[0].id) {
              this.items_poli.forEach((entry) => {
                if (entry.id == res.data.list_data[0].id) {
                  this.selectedPoli = entry;
                  // call get jadwal
                  this.getJadwalList(this.id, this.selectedPoli.id);
                }
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getJadwalList(id, poli_id) {
      this.loading = true;
      NakesService.jadwalList(id, poli_id)
        .then((res) => {
          this.jadwal_dokter = res.data.list_data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    changePoli() {
      this.getJadwalList(this.id, this.selectedPoli.id);
    },
    fetchUpdateJadwal: function (newJadwal) {
      this.formData = {
        user_id: this.id,
        hari: this.selectedDay.value,
        poli_dokter_id: this.selectedPoli.id,
        jam_dokter: newJadwal,
      };
      NakesService.updateJadwal(this.formData)
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.dialogEdit = false;
          CommonService.showSuccessToast("Berhasil, jadwal telah diupdate");
          setTimeout(() => {
            this.getJadwalList(this.id, this.selectedPoli.id);
            this.times = [{ jam_awal: null, jam_akhir: null }];
            this.selectedDay = null;
          }, 500);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchDeleteJadwal: function () {
      const data = {
        user_id: this.id,
        poli_dokter_id: this.selectedPoli?.id,
        hari: this.hari,
      };
      NakesService.deleteJadwal(data)
        .then(() => {
          this.loading = false;
          this.dialogDelete = false;
          CommonService.showSuccessToast("Berhasil delete jadwal");
          setTimeout(() => {
            this.getJadwalList(this.id, this.selectedPoli.id);
          }, 500);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    closeEdit() {
      this.getJadwalList(this.id, this.selectedPoli.id);
      this.dialogEdit = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    doSave: function () {
      this.newJadwal = [];
      if (
        this.selectedDay.value != null &&
        this.times[0].jam_awal != null &&
        this.times[0].jam_akhir != null
      ) {
        this.times.forEach((element) => {
          if (element.jam_awal != null && element.jam_akhir) {
            this.newJadwal.push({
              jam_awal: element.jam_awal,
              jam_akhir: element.jam_akhir,
            });
          }
        });
        this.fetchUpdateJadwal(this.newJadwal);
      }
    },
    tambahJadwal() {
      this.dialog = true;
    },
    editJadwal(data) {
      this.selectedDay = { value: data.hari, name: data.nama_hari };
      this.times = data.jam_dokter;
      this.hari = data.hari;
      this.poliName = this.selectedPoli.value;
      this.dialogEdit = true;
    },
    removeJadwal(data) {
      this.hari = data.hari;
      this.dialogDelete = true;
    },
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.actions-header {
  margin-right: 20px !important;
}
</style>
