<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Artikel
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari judul"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <v-btn color="primary" dark class="mb-2" @click="goToAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Artikel</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.judul"
                        label="Judul"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="currentData.selectedKategori"
                        label="Kategori"
                        clearable
                        dense
                        outlined
                        :items="items_kategori"
                        item-text="nama"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="currentData.konten"
                        outlined
                        label="Konten"
                        rows="12"
                        row-height="15"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="currentData.selectedHide"
                        label="Is Hide"
                        clearable
                        dense
                        outlined
                        :items="currentData.items_hide"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="currentData.foto_url"
                        accept="image/*"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        @change="loadImage"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="filterKategori"
              label="Kategori"
              clearable
              dense
              outlined
              :items="items_kategori"
              item-text="nama"
              @change="changeKategori"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template #[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template #[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import ArtikelService from "../../services/web_content/ArtikelService";
import KategoriArtikelService from "../../services/web_content/KategoriArtikelService";
import CommonService from "../../services/CommonService";
import * as moment from "moment";
// import KomisariatService from "../../services/KomisariatService";
// import store from "../../store/index";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Artikel",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    // selectedKomisariat: "",
    // items_komisariat: [],
    filterKategori: null,
    items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "title", sortable: false },
      { text: "Kategori", value: "article_type_nama", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Is Hide", value: "data_status", sortable: false },
      // { text: "DPD", value: "dpd_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    items_kategori: [],
    currentData: {
      id: null,
      judul: "",
      konten: "",
      selectedKategori: null,
      foto_url: null,
      fileKey: 0,
      FotoUrl: "",
      fotoKey: 0,
      items_hide: [
        {
          text: 0,
          value: "Off",
        },
        {
          text: 1,
          value: "On",
        },
      ],
      selectedHide: "",
      selectedKomisariat: "",
      selectedDpd: "",
    },
  }),
  computed: {
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListKomisariat();
    this.getListKategori();
    this.getDataFromApi();
  },
  methods: {
    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.currentData.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // changeKomisariat() {
    //   this.getDataFromApi();
    // },
    changeKategori() {
      this.getDataFromApi();
    },
    getIsHide(dataStatus) {
      console.log(dataStatus);
      if (dataStatus == 1) {
        return "On";
      } else {
        return "Off";
      }
    },

    getColor(dataStatus) {
      switch (dataStatus) {
        case 0:
          return "red";
        case 1:
          return "cyan";
        default:
          return "red";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    async getListKategori() {
      console.log("run kategroi");
      KategoriArtikelService.kategoriArtikelListAll()
        .then((res) => {
          this.items_kategori = res.data.list_data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var kategori = "";

      if (this.filterKategori) {
        kategori = this.filterKategori.id;
      }

      this.loading = true;
      ArtikelService.artikelList(page, itemsPerPage, this.search, kategori)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    goToAdd() {
      this.$router.push("/webcontent/artikel/add");
      // this.$router.push()'/webcontent/artikel/edit' + item.id);
    },

    // doAdd: async function () {
    //   this.dialog = true;
    //   this.currentData.id = null;
    //   this.currentData.judul = "";
    //   this.currentData.selectedKategori = null;
    //   this.currentData.foto_url = null;
    //   this.currentData.FotoUrl = null;
    //   this.currentData.selectedHide = null;

    //   if (!this.isAdminKomisariat) {
    //     this.currentData.selectedKomisariat = null;
    //   }

    //   this.currentData.selectedDpd = null;

    //   console.log("do add");
    // },

    doSave: async function () {
      if (this.currentData.id != null) {
        ArtikelService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update artikel berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update artikel gagal. Coba lagi");
          });
      } else {
        ArtikelService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah artikel berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah artikel gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      sessionStorage.setItem("item_artikel", JSON.stringify(item));
      this.$router.push("/webcontent/artikel/edit/" + item.id).catch((err) => {
        console.log(err);
      });
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      // this.dialog = true;
      this.currentData.judul = this.editedItem.judul;
      this.currentData.konten = this.editedItem.konten;
      this.currentData.FotoUrl = this.editedItem.foto_url;
      this.currentData.id = this.editedItem.id;
      this.currentData.fotoKey++;

      console.log(this.editedItem.judul);
      console.log(this.editedItem.konten);

      this.currentData.items_hide.forEach((entry) => {
        if (entry.text == this.editedItem.data_status) {
          this.currentData.selectedHide = entry;
        }
      });

      if (this.editedItem.kategori_artikel_id) {
        this.items_kategori.forEach((entry) => {
          if (entry.id == this.editedItem.kategori_artikel_id) {
            this.currentData.selectedKategori = entry;
          }
        });
      } else {
        this.currentData.selectedKategori = null;
      }

      if (this.editedItem.dpd_id) {
        this.items_dpd.forEach((entry) => {
          if (entry.id == this.editedItem.dpd_id) {
            this.currentData.selectedDpd = entry;
          }
        });
      }

      console.log("do edit");
    },

    deleteItemConfirm() {
      this.closeDelete();

      ArtikelService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapur artikel berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapur artikel gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
