<template>
  <v-main>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card>
        <v-card-title>
          Kategori Artikel
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari berdasarkan nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <!-- Start dialog tambah  -->
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template #activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Data Kategori Artikel</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.name"
                        label="Nama"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closePopUp">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- end dialog tambah  -->

          <!-- Start dialog delete  -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- end dialog delete  -->
        </v-card-title>
        <!-- Start datatable  -->
        <div class="pb-6 px-4">
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
          >
            <template #[`item.actions`]="{ item }">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon medium class="mr-2" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </div>
        <!-- end datatable  -->
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KategoriArtikelService from "../../services/web_content/KategoriArtikelService";
import CommonService from "../../services/CommonService";
export default {
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Web Content",
        disabled: false,
        href: "webcontent/kategori-artikel",
      },
      {
        text: "Kategori Artikel",
        disabled: true,
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        sortable: false,
        class: "actions-header",
      },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      name: "",
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      KategoriArtikelService.kategoriArtikelList(
        page,
        itemsPerPage,
        this.search,
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.name = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      if (this.currentData.name != "" && this.currentData.name != null) {
        if (this.currentData.id == null) {
          KategoriArtikelService.create(this.currentData)
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast(
                "Tambah data kategori artikel berhasil",
              );
              this.currentData.name = null;
              this.currentData.id = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah data kategori artikel gagal. Coba lagi",
              );
            });
        } else {
          KategoriArtikelService.update(this.currentData.id, this.currentData)
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast(
                "Update data kategori artikel berhasil",
              );
              this.currentData.name = null;
              this.currentData.id = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Update data kategori artikel gagal. Coba lagi",
              );
            });
        }
      } else {
        alert("Nama harus diisi.");
      }
    },

    editItem(item) {
      this.currentData.name = item.nama;
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.id = this.editedItem.id;
    },

    deleteItem(item) {
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KategoriArtikelService.delete(this.editedItem.id)
        .then(() => {
          CommonService.showSuccessToast(
            "Hapus data kategori artikel berhasil",
          );
          this.currentData.name = null;
          this.currentData.id = null;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Hapus data kategori artikel gagal. Coba lagi",
          );
        });
    },

    closePopUp() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.actions-header {
  margin-right: 20px !important;
}
</style>
