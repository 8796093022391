<template>
  <div>
    <v-card>
      <v-card-title>
        Sub-Poli
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mt-2" @click="doAdd"> Tambah </v-btn>
      </v-card-title>

      <!-- Start dialog tambah  -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Data sub-poli</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Nama"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog tambah  -->

      <!-- Start dialog delete  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Apa anda akan menghapus sub-poli ini?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">Tidak</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Ya</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog delete  -->

      <!-- Start datatable  -->
      <div class="pb-6 px-4">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </div>
      <!-- end datatable  -->
    </v-card>
  </div>
</template>

<script>
import PoliService from "../../services/PoliService";
import CommonService from "../../services/CommonService";

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    id: Number,
  },
  data: () => ({
    childKey: 0,
    name: null,
    idSubPoli: null,
    selected: [],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", align: "start", value: "nama", sortable: false },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },
    dialog: false,
    dialogDelete: false,
  }),
  computed: {},
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PoliService.subPoliList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd() {
      this.dialog = true;
    },

    doSave: async function () {
      if (this.name != "" && this.name != null) {
        if (this.idSubPoli == null) {
          PoliService.createSubPoli(this.name, this.id)
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast("Tambah data sub-poli berhasil");
              this.name = null;
              this.idSubPoli = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast(
                "Tambah data sub-poli gagal. Coba lagi",
              );
            });
        } else {
          PoliService.updateSubPoli(this.idSubPoli, this.name)
            .then(() => {
              this.dialog = false;

              CommonService.showSuccessToast("Update data poli berhasil");
              this.name = null;
              this.idSubPoli = null;

              this.getDataFromApi();
            })
            .catch((err) => {
              console.log(err);
              CommonService.showErrorToast("Update data poli gagal. Coba lagi");
            });
        }
      } else {
        alert("Nama harus diisi.");
      }
    },

    editItem(item) {
      this.idSubPoli = item.id;
      this.name = item.nama;
      this.dialog = true;
    },

    deleteItem(item) {
      this.idSubPoli = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PoliService.deleteSubPoli(this.idSubPoli)
        .then(() => {
          CommonService.showSuccessToast("Hapus sub-poli berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    closeDialog() {
      this.name = null;
      this.idSubPoli = null;
      this.dialog = false;
    },
  },
};
</script>
