<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-card>
        <v-card-title>
          <span class="text-h5">Data Berita</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="currentData.judul"
                  label="Judul"
                  clearable
                  dense
                  outlined
                  required
                ></v-text-field>
                <v-combobox
                  v-model="currentData.selectedKategori"
                  label="Kategori"
                  clearable
                  dense
                  outlined
                  :items="currentData.items_kategori"
                  item-text="nama"
                ></v-combobox>
                <v-combobox
                  v-model="currentData.selectedHide"
                  label="Publish"
                  clearable
                  dense
                  outlined
                  :items="currentData.items_hide"
                  item-text="name"
                ></v-combobox>
                <v-file-input
                  v-model="filefoto"
                  label="Foto Thumbnail (Cover)"
                  accept="image/*"
                  prepend-icon="mdi-image"
                  clearable
                  dense
                  outlined
                  @change="loadImage"
                >
                </v-file-input>
                <v-row v-if="filefoto" class="my-5">
                  <v-col cols="12" sm="6" md="5">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="currentData.fotoKey"
                        :src="currentData.FotoUrl"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="currentData.konten"
                  label="Konten"
                  clearable
                  dense
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- Start Gallery  -->
            <div>
              <CardGallery />
            </div>
            <!-- End Gallery  -->
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
// import { reactive } from "vue";
import BeritaService from "../../services/web_content/BeritaService";
import KategoriBeritaService from "../../services/KategoriBeritaService";
import CommonService from "../../services/CommonService";
import CardGallery from "../../components/CardGallery.vue";

export default {
  name: "App",
  components: {
    CardGallery,
  },
  setup() {
    return {};
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Berita",
        disabled: false,
        href: "/webcontent/berita",
      },
      {
        text: "Tambah Berita",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    filefoto: null,
    currentData: {
      judul: "",
      selectedKategori: "",
      selectedHide: "",
      konten: null,
      foto_url: "",
      fotoKey: "",
      FotoUrl: "",
      items_hide: [
        { code: 1, name: "Publish" },
        { code: 2, name: "Hide" },
      ],
      items_kategori: [],
    },
  }),
  mounted() {
    this.getListKategori();
  },
  methods: {
    loadImage: function (data) {
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },
    async getListKategori() {
      KategoriBeritaService.kategoriBeritaListAll()
        .then((res) => {
          this.currentData.items_kategori = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doSave: async function () {
      if (
        this.currentData.judul == "" ||
        this.currentData.konten == null ||
        this.filefoto == null
      ) {
        CommonService.showWarningToast("Lengkapi data terlebih dahulu!");
      } else {
        BeritaService.create(
          this.currentData,
          this.currentData.konten,
          this.filefoto,
        )
          .then(() => {
            CommonService.showSuccessToast("Tambah berita berhasil");
            this.$router.push("/webcontent/berita");
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah berita gagal. Coba lagi");
          });
      }
    },
  },
};
</script>
