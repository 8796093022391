<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="$route.meta.isHome === 1" v-model="drawer" app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img contain src="./assets/logo_sultan_agung.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dashboard"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/akun-satu-sehat"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-key-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Akun Satu Sehat SDMK</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-item v-if="getRoleId == 1" to="/e-katalog" link>
          <v-list-item-icon>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-icon>
          <v-list-item-title>E-Katalog</v-list-item-title>
        </v-list-item>

        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/nakes"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nakes</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/kegiatan"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-projector-screen</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pelatihan</v-list-item-title>
          </v-list-item>
        </router-link>

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dpw"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>DPW</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/dpd"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>DPD</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <!-- <router-link
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          to="/komisariat"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Komisariat</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <v-list-item v-if="getRoleId == 1" to="/informasi-broadcast" link>
          <v-list-item-icon>
            <v-icon>mdi-bell-ring</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Informasi Broadcast</v-list-item-title>
        </v-list-item>

        <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Web Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/agenda-pelatihan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Agenda Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/artikel"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Artikel (Healthpedia)</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/kategori-artikel"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Kategori Artikel</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/berita"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Berita</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/kategori-berita"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Kategori Berita</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/pengurus"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Pengurus</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/sejarah"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Sejarah</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/setting-rs"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Setting RS</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/struktur-organisasi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Struktur Organisasi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitator"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitator</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/summary-layanan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Summary Layanan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/testimoni"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title
                  >Testimoni ( Expertise Meets Compassions)</v-list-item-title
                >
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/visi-misi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Visi Misi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/jadwalpelatihanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jadwal Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/brosurlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Brosur</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>

        <v-list-group v-if="getRoleId == 1" no-action>
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Master</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5, 6].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-wallet-membership</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Membership</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5, 6].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/nakes"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Dokter</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/paket-membership"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Paket Membership</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/akun-satu-sehat" link>
            <v-list-item-content>
              <v-list-item-title>Akun Satu Sehat SDMK</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/keuangan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Keuangan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            to="/mutasi"
            link
          >
            <v-list-item-content>
              <v-list-item-title class="text-wrap"
                >Pengajuan Mutasi</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

          <!-- <v-list-group
          v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
          no-action
        >
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-clipboard-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Kelola Admin</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admininfokom"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin Infokom</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admindpd"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin DPD</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="getAllRole.some((r) => [1, 3, 4, 5].includes(r))"
            link
          >
            <v-list-item-content>
              <router-link
                to="/admindpw"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Admin DPW</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/tipe-paket-membership"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Tipe Paket Membership</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/harga-membership"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Harga Membership LMS</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/bank"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Bank</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master/jabatanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jabatan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/komunitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Komunitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master-layanan-superior"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Layanan Superior</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/master-partnership"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Partnership</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/poli"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Poli</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/profesi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Profesi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <router-link to="/" style="text-decoration: none; color: inherit">
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar v-if="$route.meta.isHome === 1" color="primary" dark app dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>RS Sultan Agung </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer
      padless
      color="purple"
      v-if="$route.meta.isHome === 1"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Halo Medika</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import WebsiteService from "./services/WebsiteService";
import store from "./store/index";

export default {
  data: () => ({
    drawer: null,
    currentUser: {
      nama: "",
      email: "",
    },

    env_komisariat: "",
    header_image: "",
    komisariat_id: 0,
  }),
  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    // getKomisariatNama() {
    //   return store.getters.getUser.komisariat_nama;
    // },
    getAllRole() {
      return store.getters.getUser.all_role_user;
    },
  },
  mounted() {
    this.loadWebsiteInfo();

    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX),
    );

    if (myUser) {
      this.currentUser = myUser;
    }
  },

  methods: {
    logout: async function () {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err);
        }),
      );
    },

    loadWebsiteInfo() {
      WebsiteService.websiteInfo(window.location.hostname)
        .then((res) => {
          this.env_komisariat = res.data.info.komisariat;
          this.komisariat_id = res.data.info.komisariat_id;
          this.header_image = res.data.info.header_image;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
