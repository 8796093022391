<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pengurus
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <v-btn color="purple" dark class="mb-2" @click="doAdd">
            Tambah
          </v-btn>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Pengurus</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="currentData.nama"
                        label="Nama"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="currentData.selectedJabatan"
                        label="Jabatan"
                        clearable
                        dense
                        outlined
                        :items="currentData.items_jabatan"
                        item-text="value"
                      ></v-combobox>
                    </v-col>
                  </v-row>

                  <!-- <v-row
                    ><v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="currentData.selectedDpd"
                        label="DPD"
                        clearable
                        dense
                        outlined
                        :items="items_dpd"
                        item-text="nama"
                      ></v-combobox> </v-col
                  ></v-row> -->

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-file-input
                        v-model="currentData.foto_url"
                        accept="image/*"
                        prepend-icon="mdi-image"
                        show-size
                        label="Foto"
                        @change="loadImage"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="currentData.fotoKey"
                          :src="currentData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="DPD"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              @change="changeDpd"
            ></v-combobox>
          </v-col> -->
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template #[`item.foto_url`]="{ item }">
            <v-responsive v-if="item.foto_url_original">
              <v-img :src="item.foto_url" contain width="100"></v-img>
            </v-responsive>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import DireksiService from "../../services/web_content/DireksiService";
import CommonService from "../../services/CommonService";
import MasterService from "../../services/MasterService";
// import KomisariatService from "../../services/KomisariatService";
// import store from "../../store/index";
import DPDService from "../../services/DPDService";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pengurus",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    // selectedKomisariat: "",
    items_komisariat: [],
    selectedDpd: "",
    items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Jabatan", value: "jabatan_value", sortable: false },
      // { text: "DPD", value: "dpd_name", sortable: false },
      { text: "Foto", value: "foto_url", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      nama: "",
      jabatan: "",
      foto_url: null,
      fileKey: 0,
      items_jabatan: [],
      selectedJabatan: "",
      FotoUrl: "",
      fotoKey: 0,
      selectedKomisariat: "",
      selectedDpd: "",
    },
  }),
  computed: {
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getListKomisariat();
    this.getListDpd();
    this.getListJabatan();
    this.getDataFromApi();
  },
  methods: {
    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.currentData.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    // changeDpd() {
    //   this.getDataFromApi();
    // },
    async getListDpd() {
      DPDService.dpdListAllOnly()
        .then((res) => {
          this.items_dpd = res.data.list_data;

          // if (this.getKomisariatId) {
          //   this.items_komisariat.forEach((entry) => {
          //     if (entry.id == this.getKomisariatId) {
          //       this.selectedKomisariat = entry;
          //       this.getDataFromApi();
          //     }
          //   });
          // } else {

          // }
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.FotoUrl = e.target.result;
          this.currentData.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.FotoUrl = "";
        this.currentData.foto_url = null;
      }
    },

    async getListJabatan() {
      MasterService.jabatanList()
        .then((res) => {
          console.log(res.data.list_data);
          this.currentData.items_jabatan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var dpd = "";

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      DireksiService.direksiList(page, itemsPerPage, this.search, dpd)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.id = null;
      this.currentData.nama = "";
      this.currentData.jabatan = "";
      this.currentData.foto_url = null;
      this.currentData.FotoUrl = null;

      this.currentData.selectedJabatan = null;

      // if (!this.isAdminKomisariat) {
      //   this.currentData.selectedKomisariat = null;
      // }

      this.currentData.selectedDpd = null;

      console.log("do add");
    },

    doSave: async function () {
      if (this.currentData.id != null) {
        DireksiService.update(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Update pengurus berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update pengurus gagal. Coba lagi");
          });
      } else {
        DireksiService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;
            CommonService.showSuccessToast("Tambah pengurus berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah pengurus gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.jabatan = this.editedItem.jabatan;
      this.currentData.FotoUrl = this.editedItem.foto_url;
      this.currentData.id = this.editedItem.id;
      this.currentData.fotoKey++;

      console.log(this.editedItem.jabatan);
      if (this.editedItem.jabatan) {
        this.currentData.items_jabatan.forEach((entry) => {
          if (entry.code == this.editedItem.jabatan) {
            this.currentData.selectedJabatan = entry;
          }
        });
      }

      if (this.editedItem.komisariat_id) {
        this.items_komisariat.forEach((entry) => {
          if (entry.id == this.editedItem.komisariat_id) {
            this.currentData.selectedKomisariat = entry;
          }
        });
      } else {
        this.currentData.selectedKomisariat = null;
      }

      if (this.editedItem.dpd_id) {
        this.items_dpd.forEach((entry) => {
          if (entry.id == this.editedItem.dpd_id) {
            this.currentData.selectedDpd = entry;
          }
        });
      } else {
        this.currentData.selectedDpd = null;
      }

      console.log("do edit");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      DireksiService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus pengurus berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Hapus pengurus gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
