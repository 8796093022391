<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pelatihan
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <router-link
            to="/kegiatan-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogPublish" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mem-publish data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogPublish = false"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpw"
              label="Dewan Pengurus Wilayah"
              clearable
              dense
              outlined
              :items="items_dpw"
              item-text="nama"
              :disabled="isDpwPengurus"
              @change="changeDpw"
            ></v-combobox>
          </v-col> -->
          <!-- <v-col cols="12" sm="4" md="4">
            <v-combobox
              v-model="selectedDpd"
              label="Dewan Pengurus Daerah"
              clearable
              dense
              outlined
              :items="items_dpd"
              item-text="nama"
              :disabled="isDpdPengurus"
              @change="changeDpd"
              @click="getListDpd"
            ></v-combobox>
          </v-col> -->
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.tanggal_awal`]="{ item }">
            {{ getCurrentDate(item.tanggal_awal) }}
          </template>
          <template #[`item.tanggal_akhir`]="{ item }">
            {{ getCurrentDate(item.tanggal_akhir) }}
          </template>
          <template #[`item.harga`]="{ item }">
            {{ new Intl.NumberFormat("id-ID").format(item.harga) }}
          </template>
          <template #[`item.data_status`]="{ item }">
            <div v-if="item.data_status == 0">Unpublished</div>
            <div v-else>Published</div>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex flex-row">
              <v-icon medium class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="item.data_status == 1"
                medium
                @click="deleteItem(item)"
              >
                mdi-block-helper
              </v-icon>
              <v-icon v-else medium @click="deleteItem(item)">
                mdi-check
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
// import KomisariatService from "../services/KomisariatService";
import DPWService from "../services/DPWService";
import DPDService from "../services/DPDService";
import * as moment from "moment";
import store from "../store/index";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Pelatihan",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    // selectedKomisariat: "",
    selectedDpw: "",
    selectedDpd: "",
    // items_komisariat: [],
    items_dpw: [],
    items_dpd: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "kegiatan_title", sortable: false },
      { text: "Kategori", value: "kategori_name", sortable: false },
      // { text: "DPD", value: "dpd_name", sortable: false },
      { text: "Tanggal Awal", value: "tanggal_awal", sortable: false },
      { text: "Tanggal Akhir", value: "tanggal_akhir", sortable: false },
      { text: "Harga", value: "harga", sortable: false },
      { text: "Status", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialogDelete: false,
    dialogPublish: false,
  }),
  computed: {
    getDpwPengurusId() {
      return store.getters.getUser.dpw_id;
    },
    getDpdPengurusId() {
      return store.getters.getUser.dpd_id;
    },
    // isDpwPengurus() {
    //   if (store.getters.getUser.dpw_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // isDpdPengurus() {
    //   if (store.getters.getUser.dpd_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // getKomisariatId() {
    //   return store.getters.getUser.komisariat_id;
    // },
    // isAdminKomisariat() {
    //   if (store.getters.getUser.komisariat_id) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getListDpw();
    this.getListDpd();
    // this.getListKomisariat();
    // this.getDataFromApi();
  },
  methods: {
    // changeKomisariat() {
    //   this.getDataFromApi();
    // },

    // changeDpd() {
    //   this.getDataFromApi();
    // },

    // changeDpw() {
    //   this.selectedDpd = null;
    //   this.items_dpd = [];
    //   this.getDataFromApi();
    // },

    // async getListKomisariat() {
    //   KomisariatService.komisariatListAll()
    //     .then((res) => {
    //       this.items_komisariat = res.data.list_data;

    //       if (this.getKomisariatId) {
    //         this.items_komisariat.forEach((entry) => {
    //           if (entry.id == this.getKomisariatId) {
    //             this.selectedKomisariat = entry;
    //             this.getDataFromApi();
    //           }
    //         });
    //       } else {
    //         this.getDataFromApi();
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    async getListDpw() {
      DPWService.dpwListAll()
        .then((res) => {
          this.items_dpw = res.data.list_data;

          if (this.getDpwPengurusId) {
            this.items_dpw.forEach((entry) => {
              if (entry.id == this.getDpwPengurusId) {
                this.selectedDpw = entry;

                if (this.getDpdPengurusId) {
                  this.getListDpd();
                } else {
                  this.getDataFromApi();
                }
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListDpd() {
      var dpwId = "";

      if (this.selectedDpw) {
        dpwId = this.selectedDpw.id;
      }

      DPDService.dpdListAll(dpwId)
        .then((res) => {
          this.items_dpd = res.data.list_data;

          if (this.getDpdPengurusId) {
            this.items_dpd.forEach((entry) => {
              if (entry.id == this.getDpdPengurusId) {
                this.selectedDpd = entry;
                this.getDataFromApi();
              }
            });
          } else {
            this.getDataFromApi();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      var dpd = "";

      if (this.selectedDpd) {
        dpd = this.selectedDpd.id;
      }

      this.loading = true;
      KegiatanService.kegiatanList(page, itemsPerPage, this.search, dpd)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (item.data_status == 1) this.dialogDelete = true;
      else this.dialogPublish = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      var statusSuccess = "";
      var statusGagal = "";

      if (this.editedItem.data_status == 1) {
        statusSuccess = "Berhasil meng-unpublish pelatihan";
        statusGagal = "Meng-unpublish pelatihan gagal. Coba lagi";
      } else {
        statusSuccess = "Berhasil mem-publish pelatihan";
        statusGagal = "Mem-publish pelatihan gagal. Coba lagi";
      }

      KegiatanService.delete(this.editedItem.kegiatan_id)
        .then((res) => {
          console.log(res);
          this.$toast.success(statusSuccess, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.dialogDelete = false;
          this.dialogPublish = false;

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(statusGagal, {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.dialogDelete = false;
          this.dialogPublish = false;
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
