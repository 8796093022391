<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Testimoni
          <v-spacer></v-spacer>
          <!-- <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari judul"
            single-line
            hide-details
            @keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer> -->

          <v-btn color="primary" dark class="mb-2" @click="goToAdd">
            Tambah
          </v-btn>
        </v-card-title>

        <v-row class="mx-4 my-4">
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cari Nama"
              single-line
              outlined
              dense
              hide-details
              @keyup.enter="getDataFromApi"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template #[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template #[`item.created_at`]="{ item }">
            <span>{{ getCurrentDate(item.created_at) }}</span>
          </template>
          <template #[`item.data_status`]="{ item }">
            <v-chip :color="getColor(item.data_status)" dark>
              {{ getIsHide(item.data_status) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import TestimoniService from "../../services/web_content/TestimoniService";
import * as moment from "moment";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data Testimoni",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Judul", value: "title", sortable: false },
      { text: "Tanggal", value: "created_at", sortable: false },
      { text: "Is Hide", value: "data_status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },
  }),
  computed: {},
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getIsHide(dataStatus) {
      if (dataStatus == 1) {
        return "On";
      } else {
        return "Off";
      }
    },

    getColor(dataStatus) {
      switch (dataStatus) {
        case 0:
          return "red";
        case 1:
          return "cyan";
        default:
          return "red";
      }
    },

    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      TestimoniService.testimoniList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    goToAdd() {
      this.$router.push("/webcontent/testimoni/add");
      // this.$router.push()'/webcontent/testimoni/edit' + item.id);
    },

    editItem(item) {
      sessionStorage.setItem("item_testimoni", JSON.stringify(item));
      this.$router
        .push("/webcontent/testimoni/edit/" + item.id)
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
