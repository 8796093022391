import http from "../http-common";
/* eslint-disable */
class SettingRSService {
  dataRumahSakit() {
    return http.get("/setting-rumah-sakit", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("emergency_call", data.emergency_call);
    bodyFormData.append("contact_us", data.contact_us);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("moto", data.moto);
    bodyFormData.append("link_facebook", data.link_facebook);
    bodyFormData.append("link_youtube", data.link_youtube);
    bodyFormData.append("link_instagram", data.link_instagram);
    bodyFormData.append("link_whatsapp", data.link_whatsapp);
    bodyFormData.append("link_linkedin", data.link_linkedin);
    bodyFormData.append("link_google_play", data.link_google_play);

    return http.post("/setting-rumah-sakit", bodyFormData, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("emergency_call", data.emergency_call);
    bodyFormData.append("contact_us", data.contact_us);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("moto", data.moto);
    bodyFormData.append("link_facebook", data.link_facebook);
    bodyFormData.append("link_youtube", data.link_youtube);
    bodyFormData.append("link_instagram", data.link_instagram);
    bodyFormData.append("link_whatsapp", data.link_whatsapp);
    bodyFormData.append("link_linkedin", data.link_linkedin);
    bodyFormData.append("link_google_play", data.link_google_play);

    return http.put(`/setting-rumah-sakit/${id}`, bodyFormData, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new SettingRSService();
