<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-form @submit.prevent="submitForm">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>RS Sultan Agung</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    autocomplete
                    name="email"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    label="Password"
                    autocomplete
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" style="color: white" type="submit"
                    >Login</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import WebsiteService from "../services/WebsiteService";

export default {
  name: "LoginUser",
  data: () => ({
    user: {
      email: "",
      password: "",
    },
    env_komisariat: "",
    header_image: "",
    komisariat_id: 0,
  }),
  mounted() {
    this.loadWebsiteInfo();
  },
  methods: {
    submitForm: async function () {
      this.user.hostname = window.location.hostname;

      this.$store
        .dispatch("login", this.user)
        .then(() => {
          this.$router.push("/dashboard");
          this.$router.go();
        })
        .catch((err) => {
          alert("terjadi kesalahan. coba lagi");
          console.log(err);
        });
    },

    loadWebsiteInfo() {
      WebsiteService.websiteInfo(window.location.hostname)
        .then((res) => {
          this.env_komisariat = res.data.info.komisariat;
          this.komisariat_id = res.data.info.komisariat_id;
          this.header_image = res.data.info.header_image;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
