<template>
  <v-main>
    <v-container fluid>
      <v-card class="mt-4">
        <v-card-title>
          E-Katalog
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
    </v-container>
  </v-main>
</template>
<script>
export default {
  data: () => ({}),
};
</script>
